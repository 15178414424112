import base, { Theme } from "./base";



export default {
    ...base,
    colors: {
        ...base.colors,
        background: '#0f0f0f',
        inputBackground: 'rgba(255,255,255,0.4)'
    }
} as Theme