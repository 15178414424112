import React from 'react'
import { View } from 'react-native'
import { AppearanceProvider } from 'react-native-appearance'
import { ThemeProvider } from './providers/ThemeProvider'
import Root from './screens/Root'



export default () => {

  return (
    <ThemeProvider>
      <Root />
    </ThemeProvider>
  )
}

