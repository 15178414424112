import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import env from '../config/env';
import { IDT } from '../lib/models';



const socket = io(env.host);


let globalMessages: IDT[] = []
export default () => {
    const [messages, setMessages] = useState<IDT[]>(globalMessages)

    useEffect(() => {
        globalMessages = messages
    }, [messages])

    const messageReceived = (data: IDT) => {
        console.log(`Got message ${messages.length}`, data)
        setMessages([
            ...messages,
            data
        ])
    }

    useEffect(() => {

        socket.on('message', messageReceived)

        return () => {
            socket.off('message', messageReceived)
        }
    }, [messages])

    return messages
}