import React, { PropsWithChildren } from "react"
import { ColorValue, ViewProps, useColorScheme } from "react-native"
// import { useColorScheme } from "react-native-appearance"
import base, { Theme } from "../themes/base"
import dark from "../themes/dark"
import light from "../themes/light"


const themesList = {
    light: light,
    dark: dark,
    'no-preference': base
}

export const ThemeContext = React.createContext<Theme>(themesList['light'])


export interface ThemeProviderProps extends PropsWithChildren<ViewProps> {
    
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const colorScheme = useColorScheme()

    return (
        <ThemeContext.Provider value={themesList.light}>
            {children}
        </ThemeContext.Provider>
    )
}