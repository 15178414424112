import React, { PropsWithChildren } from 'react'
import { StyleSheet, View, ViewProps } from 'react-native'
import useStream from '../hooks/useStream'
import Bubble from './Bubble'


export interface BubblesViewProps extends PropsWithChildren<ViewProps> { }

export default ({ children, style, ...props }: BubblesViewProps) => {
    const messages = useStream()
  
  
    return (
      <View style={[StyleSheet.absoluteFill, style]} removeClippedSubviews={true}  {...props}>
        { children }
  
        {
          messages.map(m => (
            <Bubble message={m} key={m._id || m.text} />
          ))
        }
      </View>
    )
  }