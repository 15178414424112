import React, { useEffect, useRef, useState } from 'react'
import { Animated, ColorValue, GestureResponderEvent, StyleSheet, TouchableWithoutFeedback, Text, useWindowDimensions } from 'react-native'
// import { GestureHandlerGestureEvent, TouchableOpacity, TouchableWithoutFeedback } from 'react-native-gesture-handler'
import { IDT } from "../lib/models"


interface BubbleProps {
    message: IDT
}


/// Start at a random position ( x, y )
/// animate towards top
const backgroundColors: ColorValue[] = [
    '#00ffbb',
    '#ccc',
    '#d23445',
    '#ffd011',
    '#37b76a'
]
export default ({ message }: BubbleProps) => {
    const [initialVal, setInitialVal] = useState({
        x: Math.random(),
        y: 0.4 + Math.random()
    })
    const dims = useWindowDimensions()
    const entryAnim = useRef(new Animated.Value(0)).current
    const moveAnim = useRef(new Animated.ValueXY(initialVal)).current
    const expandAnim = useRef(new Animated.Value(0)).current
    const [removed, setRemoved] = useState(false)
    const [paused, setPaused] = useState(false)

    const backgroundColor = useRef(backgroundColors[
        Math.round(Math.random() * (backgroundColors.length-1))
    ]).current
    
    useEffect(() => {

        Animated.spring(expandAnim, {
            toValue: paused ? 1 : 0,
            // duration: 750,
            useNativeDriver: true
        }).start()

        if (paused) {
            moveAnim.stopAnimation(v => {
                moveAnim.setValue(v)
                console.log(`Stopped: `, v)
                // setInitialVal(v)
            })
            return
        }

        xTransitionLoop()

        // TODO: Calculate distance from the current
        const pixDistance = initialVal.y * dims.height
        const durationPerPx = 25
        Animated.sequence([
            Animated.timing(entryAnim, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true
            }),

            Animated.timing(moveAnim.y, {
                duration: (pixDistance * durationPerPx) + (Math.random() * 1000),
                toValue: 0,
                useNativeDriver: true
            })
        ])
            .start((o) => {
                if (!o.finished) { return }
                setRemoved(true)
            })
    }, [paused])

    const xTransitionLoop = () => {
        const xVal = Math.random() / 2
        const leftOrRight = Math.random() > 0.5 ? 1 : -1
        const duration = 5000 + (Math.random() * 3000)

        Animated.sequence([
            Animated.timing(moveAnim.x, {
                duration,
                toValue: Math.max(0, initialVal.x + (xVal * leftOrRight)),
                useNativeDriver: true
            }),

            Animated.timing(moveAnim.x, {
                duration: duration,
                toValue: initialVal.x,
                useNativeDriver: true
            }),
        ])
        .start((o) => {
            if (!removed && o.finished) {
                xTransitionLoop()
            }
        })
    }

    const onClick = (event: GestureResponderEvent) => {
        setPaused(!paused)
    }

    if (removed) { return null }

    const xVal = moveAnim.x.interpolate({
        inputRange: [0, 1],
        outputRange: [0, dims.width - 300]
    })

    const yVal = moveAnim.y.interpolate({
        inputRange: [0, 1],
        outputRange: [-200, dims.height - 100]
    })

    const fontSize = expandAnim.interpolate({
        inputRange: [0,1],
        outputRange: [12, 40]
    })

    const animatedContainerStyles = { 
        opacity: entryAnim, 
        top: yVal, 
        left: xVal,
        borderRadius: expandAnim.interpolate({ inputRange: [0,1], outputRange: [25, 8]}),
    }

    return (
        <Animated.View key={message._id} style={[styles.container, animatedContainerStyles, { backgroundColor }]}>
            <TouchableWithoutFeedback onPress={onClick}>
                <Animated.Text style={[styles.text, { fontSize }]}>
                    {message.text}
                </Animated.Text>
            </TouchableWithoutFeedback>
        </Animated.View>
    )
}



const styles = StyleSheet.create({
    container: {
        overflow: 'visible',
        paddingVertical: 10,
        paddingHorizontal: 40,
        position: 'absolute',
        maxWidth: 300
    },
    text: {
        fontSize: 12,
        fontStyle: 'italic'
    }
})