import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, TextInput, Animated } from 'react-native';
import BubblesView from '../components/BubblesView';
import env from '../config/env';
import { ThemeContext } from '../providers/ThemeProvider';


export default () => {
  const { colors } = useContext(ThemeContext)
  const [text, setText] = useState('')

  const submit = async () => {
    
    try {

      console.log(`Host: `, env)
      const { headers } = await fetch(`${env.host}/v1/records`, {
        
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text })
      })
      setText('')
    }
    catch (ex) {
      console.error(ex)
    }
  }

  const onIDTChange = (val: string) => {
    if (val.endsWith('\n')) {
      return submit()
    }

    setText(val)
  }

  
  return (
    <View style={[styles.container, { backgroundColor: colors.background}]}>
      <BubblesView />

            {/* Main Text Input here */}
            <TextInput
        style={[
          styles.textInput, 
          { 
            backgroundColor: colors.inputBackground, 
            color: colors.inputTextColor
          }
        ]}
        placeholder={`I'm done today because . . .`}
        multiline={true}
        value={text}
        onChangeText={onIDTChange}
      />
    </View>
  );
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textInput: {
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,0.7)',
    outlineColor: null,
    outlineWidth: 0,

    width: '90%', maxWidth: 750,
    height: '50%',  minHeight: 200,

    borderColor: '#d9d9d9',
    borderRadius: 12,
    borderWidth: 1,
    padding: 12,
    fontSize: 24,

    /// Remove focusRing
    boxShadow: null,
    shadow: null,
  }
});
