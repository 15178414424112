import { ColorValue } from "react-native";


export interface Theme {
    colors: {
        background: ColorValue;
        inputBackground: ColorValue;
        inputTextColor: ColorValue;
    }
}

export default {
    colors: {
        background: '#fff',
        inputBackground: 'rgba(255,255,255,0.8)',
        inputTextColor: '#000'
    }
} as Theme